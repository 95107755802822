import { useInputControl } from '@conform-to/react'
import {
	TextInput,
	type TextInputProps,
	Textarea,
	type TextareaProps,
	Select,
	type SelectProps,
} from '@tremor/react'
import { REGEXP_ONLY_DIGITS_AND_CHARS, type OTPInputProps } from 'input-otp'
import React, { type JSX, useId } from 'react'

import { cn } from '#app/utils/misc.tsx'

import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSeparator,
	InputOTPSlot,
} from './ui/input-otp.tsx'
import { Input } from './ui/input.tsx'
import { Label } from './ui/label.tsx'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="mt-1 flex flex-col gap-1">
			{errorsToRender.map((e) => (
				<li key={e} className="text-xs text-red-600">
					{e}
				</li>
			))}
		</ul>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
	prefix,
	suffix,
	description,
	stack,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: TextInputProps
	errors?: ListOfErrors
	className?: string
	prefix?: React.ReactNode
	suffix?: React.ReactNode
	description?: React.ReactNode
	stack?: 'horizontal' | 'vertical'
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('space-y-1.5', className)}>
			<Label htmlFor={id} {...labelProps} />
			<div
				className={cn(
					'flex items-center gap-1.5',
					stack === 'vertical'
						? 'flex-col items-start sm:flex-row sm:items-center'
						: 'flex-row',
				)}
			>
				{prefix}
				<TextInput
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					className={cn('dark:bg-neutral-800', inputProps.className)}
					placeholder={inputProps.placeholder ?? ''}
					{...inputProps}
				/>
				{suffix}
			</div>
			{description && (
				<p className="text-sm text-muted-foreground">{description}</p>
			)}
			{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
		</div>
	)
}

export function FileField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('space-y-1.5', className)}>
			<Label htmlFor={id} {...labelProps} />
			<Input
				id={id}
				type="file"
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			/>
			{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
		</div>
	)
}

export function OTPField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: Partial<OTPInputProps & { render: never }>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<InputOTP
				pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
				maxLength={6}
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
				</InputOTPGroup>
				<InputOTPSeparator />
				<InputOTPGroup>
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
	description,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: TextareaProps
	errors?: ListOfErrors
	className?: string
	description?: React.ReactNode
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('space-y-1.5', className)}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				className={cn('dark:bg-neutral-800', textareaProps.className)}
				placeholder={textareaProps.placeholder ?? ''}
				{...textareaProps}
			/>
			{description && (
				<p className="text-sm text-muted-foreground">{description}</p>
			)}
			{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
		</div>
	)
}

export function SelectField({
	labelProps,
	selectProps,
	errors,
	className,
	description,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: SelectProps
	errors?: ListOfErrors
	className?: string
	description?: React.ReactNode
}) {
	const fallbackId = useId()
	const id = selectProps.id ?? selectProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={cn('space-y-1.5', className)}>
			<Label htmlFor={id} {...labelProps} />
			<Select
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				className={cn('min-w-fit dark:bg-neutral-800', selectProps.className)}
				placeholder={selectProps.placeholder ?? ''}
				{...selectProps}
			/>
			{description && (
				<p className="text-sm text-muted-foreground">{description}</p>
			)}
			{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
		</div>
	)
}

export function CheckboxField({
	labelProps,
	topLabelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label']
	topLabelProps?: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			{topLabelProps && (
				<Label
					htmlFor={id}
					{...topLabelProps}
					className={cn('mb-3 block', topLabelProps.className)}
				/>
			)}
			<div className="flex items-center gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={(state) => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={(event) => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={(event) => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs"
				/>
			</div>
			{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
		</div>
	)
}
